import { classesToClassList } from "@components/solid/classLists";
import {
  type IndexPoint,
  type LiveIndexPoint,
} from "@components/solid/Player/lib/indexPointsHelpers";
import { MEDIA_TYPES } from "@components/solid/Player/lib/toSrc";
import {
  liveIndexPoints,
  nonMusicIndexPoints,
} from "@components/solid/Player/states/indexPointsProvider";
import { playingMediaId } from "@components/solid/Player/states/playingMediaIdState";
import { nrkBulletSquare } from "@nrk/core-icons";
import type { ScrubberValues } from "@nrk/player-core";
import { clamp } from "@utils/clamp";
import { formatSecondsToHoursMinutesAndSeconds } from "@utils/date";
import dayjs from "dayjs";
import { createSignal, For, Match, Switch, type Component } from "solid-js";
import styles from "./Scrubber.module.css";

const THUMB_WIDTH = 16;

type ScrubberProps = ScrubberValues & {
  isLive: boolean;
  colorVariant: "red" | "blue";
  onScrubberChange: (position: number | Date) => void;
};

let inputRangeRef: HTMLInputElement | null = null;
let pointerLabelRef: HTMLParagraphElement | null = null;
let scrubberRef: HTMLDivElement | undefined;

const [pointerLabelPosition, setPointerLabelPosition] = createSignal<{
  x: number;
  y: number;
}>({ x: 0, y: 0 });

const [pointerLabelValue, setPointerLabelValue] = createSignal<string>("");
const [draggingPlayheadPosition, setDraggingPlayheadPosition] = createSignal<
  number | undefined
>();

let isDragging = false;

export const Scrubber: Component<ScrubberProps> = (props) => {
  const handlePlayheadPointerEvent = (event: PointerEvent) => {
    const target = event.target as HTMLElement;
    const position = getPositionFromPointerEvent(
      inputRangeRef,
      event,
      props.startPosition,
      props.endPosition,
    );

    updateLabelFromPointerEvent(props, inputRangeRef, event);

    if (event.type === "pointerdown") {
      isDragging = true;
      target.setPointerCapture(event.pointerId);
      setDraggingPlayheadPosition(
        calculatePlayheadPosition(event, inputRangeRef),
      );
    } else if (event.type === "pointerup") {
      target.releasePointerCapture(event.pointerId);
      props.onScrubberChange(position);
      isDragging = false;
      setDraggingPlayheadPosition(undefined);
    } else if (event.type === "pointermove") {
      if (isDragging) {
        setDraggingPlayheadPosition(
          calculatePlayheadPosition(event, inputRangeRef),
        );
      }
    }
  };

  return (
    <div
      class={styles.scrubberWrapper}
      classList={{ [styles.red]: props.colorVariant === "red" }}
      onPointerEnter={(event: PointerEvent) => {
        const element = event.target as HTMLElement;
        element.classList.add(styles.scrubberActive);
      }}
      onPointerLeave={(event) => {
        const inputElement = event.target as HTMLElement;
        inputElement.classList.remove(styles.scrubberActive);
      }}
    >
      <p
        classList={{
          ...classesToClassList("nrk-typography-caption--1", styles.timestamp),
          [styles.timestampShade]: props.isLive,
        }}
      >
        {props.isLive
          ? dayjs(props.startLiveTime).format("HH:mm:ss")
          : formatSecondsToHoursMinutesAndSeconds(props.playheadDisplayTime)}
      </p>
      <div class={styles.inputRangeWrapper}>
        <p
          ref={(el) => (pointerLabelRef = el)}
          id="pointerLabel"
          class={`nrk-typography-caption--1 ${styles.timestamp} ${styles.pointerLabel}`}
          style={{
            top: `${pointerLabelPosition().y}px`,
            left: `${pointerLabelPosition().x}px`,
          }}
        >
          {pointerLabelValue()}
        </p>
        <input
          class={styles.inputRange}
          ref={(el) => (inputRangeRef = el)}
          type="range"
          min={props.startPosition}
          max={props.endPosition}
          step={0.5}
          value={props.playheadPosition}
          onPointerMove={(event) => updateLabelFromPointerEvent(props, inputRangeRef, event)}
          onPointerDown={(event) => updateLabelFromPointerEvent(props, inputRangeRef, event)}
          onPointerUp={(event) => updateLabelFromPointerEvent(props, inputRangeRef, event)}
          onInput={(event) => {
            setPointerLabelValue(
              formatPointerValue(
                props.isLive,
                event.currentTarget.valueAsNumber,
                props.endPosition,
              ),
            );
            props.onScrubberChange(event.currentTarget.valueAsNumber);
          }}
        />
        <div ref={scrubberRef} class={styles.nextScrubberWrapper}>
          <div class={styles.track}></div>
          <div
            class={styles.progress}
            style={{
              width: `${(props.playheadPosition / props.endPosition) * 100}%`,
            }}
          ></div>
          <Switch>
            <Match when={playingMediaId()?.mediaType !== MEDIA_TYPES.CHANNEL}>
              <For each={nonMusicIndexPoints()}>
                {(indexPoint) => (
                  <button
                    class={styles.indexPoint}
                    style={{
                      left: `calc(${calculateIndexPointPosition(
                        indexPoint,
                        props.startPosition,
                        props.endPosition,
                      )}% - 6px)`,
                    }}
                    innerHTML={nrkBulletSquare}
                    onPointerEnter={(event) => {
                      setPointerLabelValue(indexPoint.title);
                      const nextPointerLabelPosition =
                        calculatePointerLabelPosition(
                          event,
                          inputRangeRef,
                          pointerLabelRef,
                        );
                      if (nextPointerLabelPosition) {
                        setPointerLabelPosition(nextPointerLabelPosition);
                      }
                    }}
                    onClick={() => {
                      props.onScrubberChange(indexPoint.start);
                    }}
                  ></button>
                )}
              </For>
            </Match>
            <Match
              when={
                playingMediaId()?.mediaType === MEDIA_TYPES.CHANNEL &&
                !!props.startLiveTime &&
                !!props.endLiveTime
              }
            >
              <For
                each={liveIndexPoints()?.filter(
                  (liveIndexPoint) =>
                    // Ensure start time is within the live buffer
                    liveIndexPoint.start >= props.startLiveTime! &&
                    liveIndexPoint.start <= props.endLiveTime!,
                )}
              >
                {(liveIndexPoint) => (
                  <button
                    class={styles.indexPoint}
                    style={{
                      left: `calc(${calculateLiveIndexPointPosition(
                        liveIndexPoint,
                        props.startLiveTime!,
                        props.endLiveTime!,
                      )}% - 6px)`,
                    }}
                    innerHTML={nrkBulletSquare}
                    onPointerEnter={(event) => {
                      if (liveIndexPoint.programTitle) {
                        setPointerLabelValue(liveIndexPoint.programTitle);
                      }
                      const nextPointerLabelPosition =
                        calculatePointerLabelPosition(
                          event,
                          inputRangeRef,
                          pointerLabelRef,
                        );
                      if (nextPointerLabelPosition) {
                        setPointerLabelPosition(nextPointerLabelPosition);
                      }
                    }}
                    onClick={() => {
                      props.onScrubberChange(liveIndexPoint.start);
                    }}
                  ></button>
                )}
              </For>
            </Match>
          </Switch>
          <div
            class={styles.playhead}
            onPointerDown={handlePlayheadPointerEvent}
            onPointerUp={handlePlayheadPointerEvent}
            onPointerMove={handlePlayheadPointerEvent}
            style={{
              left:
                typeof draggingPlayheadPosition() === "number" // Is dragging
                  ? `${draggingPlayheadPosition()}px`
                  : `calc(${(props.playheadPosition / props.endPosition) * 100}% - ${THUMB_WIDTH / 2}px)`,
            }}
          ></div>
        </div>
      </div>
      <p
        classList={{
          ...classesToClassList("nrk-typography-caption--1", styles.timestamp),
          [styles.timestampShade]: !props.isLive,
        }}
      >
        {props.isLive
          ? dayjs(props.endLiveTime).format("HH:mm:ss")
          : formatSecondsToHoursMinutesAndSeconds(props.endDisplayTime)}
      </p>
    </div>
  );
};

function updateLabelFromPointerEvent(
  props: ScrubberProps,
  inputRangeRef: HTMLInputElement | null,
  event: PointerEvent,
) {
  const positionFromPointer = getPositionFromPointerEvent(
    inputRangeRef,
    event,
    props.startPosition,
    props.endPosition,
  );

  setPointerLabelValue(
    formatPointerValue(props.isLive, positionFromPointer, props.endPosition),
  );
  const nextPointerLabelPosition = calculatePointerLabelPosition(
    event,
    inputRangeRef,
    pointerLabelRef,
  );
  if (nextPointerLabelPosition) {
    setPointerLabelPosition(nextPointerLabelPosition);
  }
}

function getPositionFromPointerEvent(
  inputRange: HTMLInputElement | null,
  event: PointerEvent,
  startPostion: number,
  endPosition: number,
): number {
  if (!inputRange) {
    return 0;
  }
  const min = parseFloat(inputRange.min);
  const max = parseFloat(inputRange.max);
  const step = parseFloat(inputRange.step) || 1;
  const inputBoundingClientRect = inputRange.getBoundingClientRect();
  const pointerPosition = event.clientX - inputBoundingClientRect.left;

  // Adjust for the thumb width
  const adjustedPointerPosition = pointerPosition - THUMB_WIDTH / 2;

  // Ensure the pointer position is within the bounds of the input range
  const clampedPointerPosition = clamp(
    adjustedPointerPosition,
    0,
    inputBoundingClientRect.width - THUMB_WIDTH,
  );

  // Calculate the percentage of the pointer position relative to the range width
  const percentage =
    clampedPointerPosition / (inputBoundingClientRect.width - THUMB_WIDTH);

  // Calculate the position based on the percentage
  const position = min + percentage * (max - min);

  // Round the position to the nearest step and clamp it to the range
  return clamp(Math.round(position / step) * step, startPostion, endPosition);
}

function calculatePointerLabelPosition(
  event: PointerEvent,
  inputElement: HTMLInputElement | null,
  pointerLabel: HTMLParagraphElement | null,
): { x: number; y: number } | undefined {
  if (!inputElement || !pointerLabel) {
    return;
  }
  const labelWidth = pointerLabel.offsetWidth;
  const labelHeight = pointerLabel.offsetHeight;
  const inputTop = inputElement.getBoundingClientRect().top;

  const y = inputTop - labelHeight;
  const x = event.clientX - labelWidth / 2;
  return { x, y };
}

function calculatePlayheadPosition(
  event: PointerEvent,
  inputElement: HTMLInputElement | null,
): number | undefined {
  if (!inputElement) {
    return;
  }
  const inputElementBoundingRect = inputElement.getBoundingClientRect();
  const playheadPosition =
    event.clientX - inputElementBoundingRect.left - THUMB_WIDTH / 2;
  return clamp(playheadPosition, 0, inputElementBoundingRect.width);
}

function formatTimestampLive(seconds: number, endPosition: number): string {
  return dayjs()
    .subtract(endPosition, "seconds")
    .add(seconds, "seconds")
    .format("HH:mm:ss");
}

function formatPointerValue(
  isLive: boolean,
  position: number,
  endPosition: number,
): string {
  return isLive
    ? formatTimestampLive(position, endPosition)
    : formatSecondsToHoursMinutesAndSeconds(position);
}

function calculateIndexPointPosition(
  indexPoint: IndexPoint,
  startPosition: number,
  endPosition: number,
): number {
  const duration = endPosition - startPosition;
  const position = ((indexPoint.start - startPosition) / duration) * 100;
  return clamp(position, 0, 100);
}

function calculateLiveIndexPointPosition(
  liveIndexPoint: LiveIndexPoint,
  startLiveTime: Date,
  endLiveTime: Date,
): number {
  const duration = endLiveTime.getTime() - startLiveTime.getTime();
  const position =
    ((liveIndexPoint.start.getTime() - startLiveTime.getTime()) / duration) *
    100;
  return clamp(position, 0, 100);
}
