import { button, classesToClassList } from "@components/solid/classLists";
import ProgressMediaPlay from "@components/solid/common/ProgressMediaPlay";
import {
  isLive,
  isLiveTime,
  isMuted,
  isPlayerPlaying,
  play,
  playbackRate,
  seekTo,
  seekToLive,
  seekToLiveTime,
  seekToRelativeTime,
  setVolume,
  toggleMute,
  togglePlayPause,
  unMute,
  volume,
} from "@components/solid/Player/AudioPlayer";
import { DirectButton } from "@components/solid/Player/controls/DirectButton";
import { FfwButton } from "@components/solid/Player/controls/FfwButton";
import IndexPointsAndQueue from "@components/solid/Player/controls/IndexPointsAndQueue";
import { PlaybackSpeed } from "@components/solid/Player/controls/PlaybackSpeed";
import { PlayPauseButton } from "@components/solid/Player/controls/PlayPauseButton";
import { RwdButton } from "@components/solid/Player/controls/RwdButton";
import { Scrubber } from "@components/solid/Player/controls/Scrubber";
import { VolumeButton } from "@components/solid/Player/controls/VolumeButton";
import { currentlyPlaying } from "@components/solid/Player/CurrentlyPlayingProvider";
import { OpenFullscreenPlayerButton } from "@components/solid/Player/FullscreenButton";
import { MEDIA_TYPES } from "@components/solid/Player/lib/toSrc";
import { CurrentlyPlaying } from "@components/solid/Player/MiniPlayer/CurrentlyPlaying";
import { playerProgress } from "@components/solid/Player/states/playerProgress";
import { playingMediaId } from "@components/solid/Player/states/playingMediaIdState";
import { scrubberState } from "@components/solid/Player/states/scrubberState";
import { onMount, Show, type Component } from "solid-js";
import styles from "./Miniplayer.module.css";

export const MiniPlayer: Component = () => {
  onMount(() => {
    document.body.classList.add(styles.miniplayerOffset);
  });

  return (
    <div id="mini-player" class={styles.MiniPlayerWrapper}>
      <div
        classList={classesToClassList(styles.MiniPlayer, styles.MiniPlayerGrid)}
      >
        <div class={styles.start}>
          <button
            onClick={() => {
              console.log("TODO");
            }}
            classList={{
              ...button({ type: "ghost" }),
              ...classesToClassList(styles.currentlyPlayingButton),
            }}
          >
            <CurrentlyPlaying {...currentlyPlaying()} />
          </button>
        </div>
        <div class={styles.centerTop}>
          <div class={styles.centerTopCenter}>
            <RwdButton onClick={() => seekToRelativeTime(-15)} />
            <PlayPauseButton
              onClick={() => togglePlayPause()}
              isPlaying={isPlayerPlaying()}
            />
            <FfwButton onClick={() => seekToRelativeTime(15)} />
          </div>
          <div class={styles.centerTopEnd}>
            <Show when={!isLive()}>
              <PlaybackSpeed rate={playbackRate()} />
            </Show>
            <Show when={isLive()}>
              <DirectButton
                onClick={() => {
                  seekToLive();
                  if (!isPlayerPlaying()) {
                    play();
                  }
                }}
                isLiveTime={isLiveTime()}
              />
            </Show>
          </div>
        </div>
        <div class={styles.centerBottom}>
          <Scrubber
            startPosition={scrubberState().startPosition}
            startDisplayTime={scrubberState().startDisplayTime}
            startLiveTime={scrubberState().startLiveTime}
            endPosition={scrubberState().endPosition}
            endDisplayTime={scrubberState().endDisplayTime}
            endLiveTime={scrubberState().endLiveTime}
            playheadPosition={scrubberState().playheadPosition}
            playheadDisplayTime={scrubberState().playheadDisplayTime}
            playheadLiveTime={scrubberState().playheadLiveTime}
            isLive={isLive()}
            colorVariant={
              playingMediaId()?.mediaType === MEDIA_TYPES.PODCAST
                ? "blue"
                : "red"
            }
            onScrubberChange={(seekPoint) => {
              if (typeof seekPoint === "number") {
                seekTo(seekPoint);
              } else {
                seekToLiveTime(seekPoint);
              }
              if (!isPlayerPlaying()) {
                play();
              }
            }}
          />
        </div>
        <div class={styles.end}>
          <IndexPointsAndQueue />
          <VolumeButton
            isMuted={isMuted()}
            onClick={() => {
              toggleMute();
            }}
            volume={volume()}
            setVolume={(volume) => setVolume(volume)}
            unMute={() => unMute()}
          />
          <OpenFullscreenPlayerButton />
        </div>
        <div class={styles.endSmallScreen}>
          <button onClick={() => togglePlayPause()}>
            <ProgressMediaPlay
              progress={playerProgress()}
              size="small"
              variant="overlay"
              playing={isPlayerPlaying()}
            />
          </button>
        </div>
      </div>
    </div>
  );
};
